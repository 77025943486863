import "./bootstrap";
import "bootstrap";
import "scrollnav";
import Vue from "vue";
import Swiper from "swiper";
import lightbox from "lightbox2";
import Sticky from "sticky-js";

/* Components */
import ClubNews from "./components/ClubNews";

/* Initialize */
/* Render Vue.js instance on  #club-news div */
window.onload = function () {
    const app = new Vue({
        el: "#club-news",
        components: {
            ClubNews
        }
    });
};

$(document).ready(function () {
    //initialize swiper when document ready
    var homeSlider = new Swiper(".swiper-container", {
        // Optional parameters
        loop: true,
        effect: "fade",
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        }
    });

    var singleEventSlider = new Swiper(".swiper-container", {
        // Optional parameters
        loop: true,
        effect: "fade",
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        }
    });

    $("#menu-icon").on("click", function () {
        console.log("click");
        $("body").toggleClass("menu-is-open");
        $(this).toggleClass("is-active");
    });

    lightbox.option({
        resizeDuration: 200,
        wrapAround: true,
        albumLabel: "Image %1 sur %2"
    });

    var sticky = new Sticky(".aside");

    $("a[href*='#']:not([href='#'])").click(function () {
        if (
            location.hostname == this.hostname &&
            this.pathname.replace(/^\//, "") == location.pathname.replace(/^\//, "")
        ) {
            var anchor = $(this.hash);
            anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) + "]");
            if (anchor.length) {
                $("html, body").animate(
                    {
                        scrollTop: anchor.offset().top - 60
                    },
                    1500
                );
            }
        }
    });

    /* Add collapse class to footer ul when screen size is on mobile  < 768px */
    var $window = $(window),
        $html = $("html"),
        $menus = $(".mobile-collapsable");

    $window
        .resize(function resize() {
            if ($window.width() < 768) {
                $menus.each(function (item) {
                    $(this).addClass("collapse");
                });
                return true;
            }
            $menus.each(function (item) {
                $(this).removeClass("collapse");
            });
        })
        .trigger("resize");

    /**
     * Fix topbar when scrollpos > 100px and (if sidebar needs to be sticky everywhere, uncomment) sidebar when scrollpos >300px
     */
    window.onscroll = function changeClass() {
        var scrollPosY = window.pageYOffset | document.body.scrollTop;
        if (scrollPosY > 100) {
            $(".topbar").addClass("topbar-fixed");
        } else if (scrollPosY <= 100) {
            $(".topbar").removeClass("topbar-fixed");
        }
        /** if (scrollPosY > 300) {
         * $(".aside").addClass("sidebar-fixed");
         * } else if (scrollPosY <= 300) {
         * $(".aside").removeClass("sidebar-fixed");
    }
         */
    };

    /**
     * Search input in topbar
     * @type {*|jQuery.fn.init|jQuery|HTMLElement}
     */
    var menuDesktop = $("#menu-desktop");
    var searchInput = $("#search-input");
    var closeIcon = $("#close-icon");
    var searchIcon = $("#search-icon");
    var triggerSearch = $("#trigger-icon");

    closeIcon.css("display", "none");

    searchIcon.click(function () {
        menuDesktop.addClass("hide");
        searchInput.addClass("visible");
        searchInput.css("z-index", "999");
        closeIcon.css("display", "block");
        searchIcon.css("display", "none");
    });

    closeIcon.click(function () {
        menuDesktop.removeClass("hide");
        searchInput.removeClass("visible");
        closeIcon.css("display", "none");
        searchIcon.css("display", "block");
    });

    /**
     * Image gallery, height equal to width
     */
    $(window).resize(function () {
        let cw = $('.gallery-single-img').width();
        $('.gallery-single-img').css({'height': cw + 'px'});
    }).resize();

});
