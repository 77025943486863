<template>

    <section class="page-content">
        <div class="container">
            <div class="row mt-5">

                <div class="club-news-send-message col-md-3">
                    <div class="club-news-send-message-form">
                        <p class="club-news-send-message-text"><strong>Question ou demande</strong></p>
                        <form class="">
                            <div class="form-group">
                                <label for="club-news-form-firstname">Nom et prénom</label>
                                <input type="text" id="club-news-form-firstname" placeholder="Nom et prénom...">
                            </div>

                            <div class="form-group">
                                <label for="club-news-form-lastname">Téléphone</label>
                                <input type="text" id="club-news-form-lastname" placeholder="Numéro...">
                            </div>

                            <div class="form-group">
                                <label for="club-news-form-email">Email</label>
                                <input type="email" id="club-news-form-email" placeholder="Adresse email...">
                            </div>

                            <div class="form-group">
                                <label for="club-news-form-message">Message</label>
                                <textarea id="club-news-form-message" rows="3" placeholder="Texte..."></textarea>
                            </div>
                            <button type="submit" class="btn btn-secondary">Envoyer</button>
                        </form>
                    </div>
                </div>

                <div class="club-news-message-area col-md-6">
                    <div class="row">
                        <h2 style="padding-left: 15px">Messages du comité</h2>
                        <template v-for="News in NewsListFiltered">
                            <div class="col-md-12 club-news-col">
                                <div class="club-news-content">
                                    <p>Date bientôt dispo . . .</p>
                                    <h3 v-html="News.title"></h3>
                                    <p v-html="News.content"></p>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="club-news-filter-col col-md-3">
                    <div class="club-news-filter">
                        <p class="club-news-filter-text"><strong>Filtrer les messages</strong></p>

                        <input name="search" class="form-control" type="text" placeholder="Rechercher..."
                               aria-label="Search">


                        <div class="form-group">
                            <label class="club-news-filter-select-label" for="club-news-filter-select">Choisir une
                                catégorie</label>
                            <select class="form-control" id="club-news-filter-select">
                                <option>Tous les messages</option>
                                <option>Information</option>
                                <option>Activité</option>
                                <option>Restaurant</option>
                                <option>Nouveauté</option>
                                <option>Divers</option>
                            </select>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
    export default {
        name: "ClubNews",
        data() {
            return {
                NewsList: Object,
                NewsListFiltered: Object
            }
        },
        mounted() {
            fetch("/wp-json/custom/v1/banneretnews", {
                method: "get"
            })
                .then(response => response.json())
                .then(response => {
                    this.NewsList = response[0];
                    this.NewsListFiltered = response[0];
                });

        }
    }
</script>

<style lang="scss" scoped>

    .club-news-message-area {
        h2 {
            padding-left: 15px;
        }
    }

    .club-news-col {
        &:not(:last-child) {
            padding-bottom: 20px
        }
    }

    .club-news-content {
        border: 1px solid black;
        padding: 20px;

        p:first-child {
            font-style: italic;
            font-size: 16px;
            margin: 0
        }

    }

    .club-news-send-message-form {
        background-color: #F2E113;

        input, textarea {
            width: 100%;
        }

        .club-news-send-message-text {
            border-bottom: 1px solid black;
            display: inline-block;
        }
    }

    .club-news-filter {
        background-color: #292929;

        .club-news-filter-text {
            display: inline-block;
            border-bottom: 1px solid white;
        }

        p, label {
            color: white;
        }

        .club-news-filter-select-label {
            padding-top: 20px;
        }

    }

    .club-news-filter, .club-news-send-message-form {
        padding: 20px;
    }


</style>